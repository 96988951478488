<template>
  <v-main>
    <BonusEditDialog
      v-model="dialogBonus"
      :item="itemBonus"
      :request="requestData"
      @save="saveBonusDialog"
    />
    <FineEditDialog
      v-model="dialogFine"
      :item="itemFine"
      :request="requestData"
      @save="saveFineDialog"
    />

    <v-card
      class="ma-2"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            sm="12"
            md="4"
          >
            <v-card-title>Табель по сотрудникам</v-card-title>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-menu
              v-model="dialog"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="humanDateSelect"
                  v-bind="attrs"
                  label="Месяц"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateSelect"
                :show-current="false"
                scrollable
                type="month"
                @input="dialog = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="counterSelect"
              :disabled="getCounterList.length < 3"
              :items="getCounterList"
              hide-details
              label="Точка продаж"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="counterData.rent"
              :loading="loadingCounterData"
              :rules="[rules.required, rules.positiveNumber]"
              label="Аренда"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="counterData.base_salary"
              :loading="loadingCounterData"
              :rules="[rules.required, rules.positiveNumber]"
              label="Базовая з/п"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="counterData.bonus_salary_cap"
              :loading="loadingCounterData"
              :rules="[rules.required, rules.positiveNumber]"
              label="План выручки"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="counterData.marketing"
              :loading="loadingCounterData"
              :rules="[rules.required, rules.positiveNumber]"
              label="Маркетинг"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="counterData.other"
              :loading="loadingCounterData"
              :rules="[rules.required, rules.positiveNumber]"
              label="Остальные расходы"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-btn
              :disabled="!counterDataHasModify"
              :loading="loadingCounterData"
              color="success"
              @click="saveCounterData"
            >
              Сохранить
            </v-btn>
            <EmployeeListDialog :loading="loading" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <HandyScroll ref="tableDataHandScrolls">
              <v-data-table
                :headers="headersTable"
                :items="getTableData"
                :loading="loading"
                dense
                disable-pagination
                disable-sort
                hide-default-footer
                item-key="date"
                width="100%"
                class="v-datatable-table"
              >
                <template #[`item.shifts`]="{ item }">
                  <v-select
                    v-if="item.shifts !== false"
                    v-model="item.shifts"
                    :item-disabled="(item) => !item.is_active"
                    :items="getEmployeeList()"
                    :menu-props="{ offsetY: true }"
                    chips
                    class="datatable__shift-v-select"
                    dense
                    hide-details
                    item-text="first_name"
                    item-value="id"
                    multiple
                    small-chips
                    @change="saveEmployeeShift(item)"
                  />
                </template>
                <template #[`item.proceeds`]="{ item }">
                  {{ item.proceeds | currency }}
                </template>
                <template #[`item.proceeds_cash`]="{ item }">
                  {{ item.proceeds_cash | currency }}
                </template>
                <template #[`item.proceeds_card`]="{ item }">
                  {{ item.proceeds_card | currency }}
                </template>
                <template #[`item.commission_evotor`]="{ item }">
                  {{ item.commission_evotor | currency }}
                </template>
                <template #[`item.rent`]="{ item }">
                  {{ item.rent | currency }}
                </template>
                <template #[`item.salary`]="{ item }">
                  {{ item.salary | currency }}
                </template>
                <template #[`item.salary_bonus_fine`]="{ item }">
                  {{ item.salary_bonus_fine | currency }}
                </template>
                <template #[`item.cost_price`]="{ item }">
                  {{ item.cost_price | currency }}
                </template>
                <template #[`item.marketing`]="{ item }">
                  {{ item.marketing | currency }}
                </template>
                <template #[`item.other`]="{ item }">
                  {{ item.other | currency }}
                </template>
                <template #[`item.margin`]="{ item }">
                  {{
                    (item.proceeds -
                      item.commission_evotor -
                      item.rent -
                      item.salary -
                      item.salary_bonus -
                      item.cost_price -
                      item.marketing -
                      item.other)
                      | currency
                  }}
                </template>
              </v-data-table>
            </HandyScroll>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headersSalary"
              :items="getSalaryData"
              :loading="loading"
              dense
              disable-pagination
              disable-sort
              hide-default-footer
              item-key="id"
              width="100%"
            >
              <template #[`item.salary_base`]="{ item }">
                {{ item.salary_base | currency }}
              </template>
              <template #[`item.salary_base_bonus`]="{ item }">
                {{ item.salary_base_bonus | currency }}
              </template>
              <template #[`item.salary_bonus`]="{ item }">
                <span
                  style="cursor: pointer"
                  @click="openBonusDialog(item)"
                >{{ item.salary_bonus | currency
                }}<v-icon
                  class="ml-2"
                  small
                >mdi-pencil</v-icon></span>
              </template>
              <template #[`item.salary_fine`]="{ item }">
                <span
                  style="cursor: pointer"
                  @click="openFineDialog(item)"
                >{{ item.salary_fine | currency
                }}<v-icon
                  class="ml-2"
                  small
                >mdi-pencil</v-icon></span>
              </template>
              <template #[`item.salary_amount`]="{ item }">
                {{ item.salary_amount | currency }}
              </template>
              <template #[`item.employee_data.prepaid`]="{ item }">
                <PrepaidEditDialog
                  v-model="item.employee_data.prepaid"
                  :item="item"
                  :request="requestData"
                />
              </template>
              <template #[`item.salary`]="{ item }">
                {{ item.salary | currency }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import HandyScroll, { EventBus } from "vue-handy-scroll";

import tables from "@/api/tables";
import BonusEditDialog from "@/components/tables/BonusEditDialog";
import EmployeeListDialog from "@/components/tables/EmployeeListDialog";
import FineEditDialog from "@/components/tables/FineEditDialog";
import PrepaidEditDialog from "@/components/tables/PrepaidEditDialog";

export default {
  name: "TableView",
  components: {
    BonusEditDialog,
    EmployeeListDialog,
    FineEditDialog,
    HandyScroll,
    PrepaidEditDialog,
  },
  data: () => ({
    counterSelect: "",
    dateSelect: new Date().toISOString().substr(0, 7),
    dialog: false,

    dialogBonus: false,
    dialogFine: false,
    itemFine: {},
    itemBonus: {},

    counter: {},
    counterData: {},
    counterOriginalData: {},
    employeeData: [],
    tableData: [],

    headersSalary: [
      { text: "Имя", value: "first_name" },
      { text: "Смен", value: "shifts" },
      { text: "Базовая з/п", value: "salary_base" },
      { text: "Базовые бонусы", value: "salary_base_bonus" },
      { text: "Бонусы", value: "salary_bonus" },
      { text: "Штрафы", value: "salary_fine" },
      { text: "Зарплата", value: "salary_amount" },
      { text: "Аванс", value: "employee_data.prepaid" },
      { text: "Остаток", value: "salary" },
    ],
    headersTable: [
      { text: "Дата", value: "pretty_date" },
      { text: "Сотрудники", value: "shifts" },
      { text: "Выручка", value: "proceeds" },
      { text: "Выручка нал.", value: "proceeds_cash" },
      { text: "Выручка безнал.", value: "proceeds_card" },
      { text: "Ком. Эвотор", value: "commission_evotor" },
      { text: "Аренда", value: "rent" },
      { text: "Зарплата", value: "salary" },
      { text: "Бонусы/штрафы", value: "salary_bonus_fine" },
      { text: "Себестоимость", value: "cost_price" },
      { text: "Маркетинг", value: "marketing" },
      { text: "Прочие расходы", value: "other" },
      { text: "Чистая прибыль", value: "margin" },
    ],

    loadingCounterData: true,
    loadingEmployeeData: true,
    loadingTableData: true,

    rules: {
      required: (v) => !!v || "Обязательное поле",
      positiveNumber: (v) =>
        !v ||
        (window.isFinite(v) &&
          Number.parseFloat(v) >= 0 &&
          Number.parseFloat(v) === Number(v)) ||
        "Введите целое число",
    },
  }),
  computed: {
    ...mapGetters("clients", ["getCounterList", "getEmployeeList"]),
    requestData() {
      return {
        date: `${this.dateSelect}-01`,
        counter: this.counterSelect,
      };
    },
    getSalaryData() {
      // генерация данных для таблицы зарплат
      if (this.tableData.length === 0) return [];

      // количество смен
      let salaryData = this.getEmployeeList().map((el) => ({
        ...el,
        shifts: this.tableData.reduce(
          (acc, date) =>
            date.shifts && date.shifts.includes(el.id) ? acc + 1 : acc,
          0
        ),
        employee_data:
          this.employeeData.find((data) => data.employee === el.id) || {},
      }));

      // базовая зарплата и премия
      salaryData = salaryData.map((el) => ({
        ...el,
        salary_base: this.counterData.base_salary
          ? this.counterData.base_salary * el.shifts
          : 0,
        salary_base_bonus: this.tableData.reduce(
          (acc, date) =>
            date.shifts && date.shifts.includes(el.id) && date.salary_bonus
              ? acc + date.salary_bonus / date.shifts.length
              : acc,
          0
        ),
        salary_bonus: el.employee_data
          ? Number(el.employee_data.bonus_senior_salesman) +
            Number(el.employee_data.bonus_repair) +
            Number(el.employee_data.bonus_receipt_1) +
            Number(el.employee_data.bonus_receipt_2) +
            Number(el.employee_data.bonus_proceeds) +
            Number(el.employee_data.bonus_accessories) +
            Number(el.employee_data.bonus_comprehensive_sales)
          : 0,
        salary_fine: el.employee_data
          ? Number(el.employee_data.fine_absence) +
            Number(el.employee_data.fine_delay) +
            Number(el.employee_data.fine_mess) +
            Number(el.employee_data.fine_lack_of_activity)
          : 0,
      }));

      // итоговая зарплата
      salaryData = salaryData.map((el) => ({
        ...el,
        salary_amount:
          el.salary_base +
          el.salary_base_bonus +
          el.salary_bonus -
          el.salary_fine,
        salary:
          el.salary_base +
          el.salary_base_bonus +
          el.salary_bonus -
          el.salary_fine -
          (el.employee_data.prepaid ? Number(el.employee_data.prepaid) : 0),
      }));

      // только те, у кого есть смены
      salaryData = salaryData.filter((el) => el.shifts > 0);

      return salaryData;
    },
    getTableData() {
      // генерация данных для табеля
      if (this.tableData.length === 0) return [];
      const salary = this.counterData.base_salary
        ? this.counterData.base_salary
        : 0;
      const rent = this.counterData.rent
        ? this.counterData.rent / this.tableData.length
        : 0;
      const marketing = this.counterData.marketing
        ? this.counterData.marketing / this.tableData.length
        : 0;
      const other = this.counterData.other
        ? this.counterData.other / this.tableData.length
        : 0;
      const salary_bonus_fine =
        this.getSalaryData.length > 0
          ? this.getSalaryData.reduce(
              (acc, el) => acc + el.salary_bonus - el.salary_fine,
              0
            ) / this.tableData.length
          : 0;
      let tableData = this.tableData.map((el) => ({
        ...el,
        pretty_date: Number.parseInt(el.date.split("-")[2]),
        shifts: el.shifts ? el.shifts.sort() : [],
        rent,
        marketing,
        other,
        salary_bonus_fine:
          (Number(salary_bonus_fine) || 0) + Number(el.salary_bonus),
        salary: el.shifts ? salary * el.shifts.length : 0,
      }));

      // добавляем строку с итогами
      tableData.push(
        tableData.reduce((acc, value) => ({
          pretty_date: "Итого",
          shifts: false,
          proceeds: Number(acc.proceeds) + Number(value.proceeds),
          proceeds_cash:
            Number(acc.proceeds_cash) + Number(value.proceeds_cash),
          proceeds_card:
            Number(acc.proceeds_card) + Number(value.proceeds_card),
          commission_evotor:
            Number(acc.commission_evotor) + Number(value.commission_evotor),
          salary: Number(acc.salary) + Number(value.salary),
          salary_bonus: Number(acc.salary_bonus) + Number(value.salary_bonus),
          salary_bonus_fine:
            Number(acc.salary_bonus_fine) + Number(value.salary_bonus_fine),
          rent: this.counterData.rent ? this.counterData.rent : 0,
          marketing: this.counterData.marketing
            ? this.counterData.marketing
            : 0,
          other: this.counterData.other ? this.counterData.other : 0,
          cost_price: Number(acc.cost_price) + Number(value.cost_price),
          margin: Number(acc.margin) + Number(value.margin),
        }))
      );
      return tableData;
    },
    humanDateSelect() {
      const options = {
        year: "numeric",
        month: "long",
      };
      return this.dateSelect
        ? new Date(Date.parse(this.dateSelect)).toLocaleString("ru", options)
        : this.dateSelect;
    },
    loading() {
      return (
        this.loadingCounterData ||
        this.loadingEmployeeData ||
        this.loadingTableData
      );
    },
    counterDataHasModify() {
      return (
        Number.parseFloat(this.counterOriginalData.base_salary) !==
          Number.parseFloat(this.counterData.base_salary) ||
        Number.parseFloat(this.counterOriginalData.rent) !==
          Number.parseFloat(this.counterData.rent) ||
        Number.parseFloat(this.counterOriginalData.marketing) !==
          Number.parseFloat(this.counterData.marketing) ||
        Number.parseFloat(this.counterOriginalData.other) !==
          Number.parseFloat(this.counterData.other)
      );
    },
  },
  watch: {
    counterSelect(v) {
      this.counter = this.getCounterList.find((el) => el.value === v);
      this.updateAll();
    },
    dateSelect() {
      this.updateAll();
    },
    getCounterList(v) {
      // после обновления списка точек продаж, делаем первую по умолчанию
      if (v.length > 0) this.counterSelect = v[0].value;
    },
    loading() {
      EventBus.$emit("update", {sourceElement: this.$refs.tableDataHandScrolls.$el});
    },
  },
  created() {
    this.updateCounterList();
    this.updateEmployeeList();
  },
  methods: {
    ...mapActions("clients", ["updateCounterList", "updateEmployeeList"]),
    updateAll() {
      this.updateCounterData();
      this.updateEmployeeData();
      this.updateTableData();
    },
    updateCounterData() {
      this.loadingCounterData = true;
      this.counterData = [];
      tables
        .getCounterData(this.requestData)
        .then(
          (response) => (
            (this.counterData = Object.assign({}, response.data[0])),
            (this.counterOriginalData = Object.assign({}, response.data[0]))
          )
        )
        .finally(() => (this.loadingCounterData = false));
    },
    updateEmployeeData() {
      this.loadingEmployeeData = true;
      this.employeeData = [];
      tables
        .getEmployeeData(this.requestData)
        .then((response) => (this.employeeData = response.data))
        .finally(() => (this.loadingEmployeeData = false));
    },
    updateTableData() {
      this.loadingTableData = true;
      this.tableData = [];
      tables
        .getTableData(this.requestData)
        .then(
          (response) =>
            (this.tableData = response.data.map((el) => ({
              ...el,
              salary_bonus: this.getSalaryBonus(el.proceeds),
            })))
        )
        .finally(() => (this.loadingTableData = false));
    },
    saveCounterData() {
      this.loadingCounterData = true;
      tables
        .updateCounterData(this.counterData)
        .then(
          (response) => (
            this.$store.dispatch(
              "alertSuccess",
              "Информация о точке обновлена"
            ),
            (this.counterData = Object.assign({}, response.data)),
            (this.counterOriginalData = Object.assign({}, response.data))
          )
        )
        .finally((this.loadingCounterData = false));
    },
    saveEmployeeShift({ date, shifts }) {
      this.loadingTableData = true;
      tables
        .updateEmployeeShift({ date, shifts, counter: this.counterSelect })
        .then((response) => {
          this.$store.dispatch(
            "alertSuccess",
            `Смена за ${this.$options.filters.dateformat(date, false)} изменена`
          ),
            (this.tableData = this.tableData.map((el) => ({
              ...el,
              shifts: el.date === date ? response.data.shifts : el.shifts,
            }))),
            this.updateEmployeeData();
        })
        .catch(
          (error) => (
            this.$store.dispatch("alertError", error), this.updateTableData()
          )
        )
        .finally((this.loadingTableData = false));
    },
    openBonusDialog(item) {
      this.itemBonus = item;
      this.dialogBonus = true;
    },
    saveBonusDialog() {
      this.updateEmployeeData();
    },
    openFineDialog(item) {
      this.itemFine = item;
      this.dialogFine = true;
    },
    saveFineDialog() {
      this.updateEmployeeData();
    },
    getSalaryBonus(value) {
      const base = Number(this.counterData.bonus_salary_cap);
      const proceeds = Number(value);
      let bonus = 0;

      if (proceeds > base * 0.4) bonus = proceeds * 0.02;
      if (proceeds > base * 0.6) bonus = proceeds * 0.03;
      if (proceeds > base * 0.8) bonus = proceeds * 0.04;
      if (proceeds > base * 1.0) bonus = proceeds * 0.05;

      return bonus;
    },
  },
};
</script>

<style scope>
.datatable__shift-v-select {
  width: 10em;
}
.v-datatable-table tbody tr td:last-of-type {
  font-weight: 800;
}
.v-datatable-table tbody tr:last-of-type td {
  font-weight: 800;
  font-size: 0.75em !important;
}
.v-datatable-table .v-data-table__wrapper {
  overflow: unset;
}
.employeetable__prepaid {
  width: 10em;
}
.handy-scroll {
  margin-bottom: 36px !important;
}
</style>
