<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="40%"
  >
    <v-card tile>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="save"
      >
        <v-card-title class="headline">
          Бонусы
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-for="field in textFields"
            :key="field.key"
            v-model="data[field.key]"
            :label="field.text"
            :rules="[rules.required, rules.positiveNumber]"
          />
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
            color="success"
            type="submit"
            :disabled="!valid"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import tables from "@/api/tables";

export default {
  name: "BonusEditDialog",
  props: {
    value: Boolean,
    item: Object,
    request: Object,
  },
  data: () => ({
    valid: false,

    textFields: [
      { key: "bonus_senior_salesman", text: "Старший продавец" },
      { key: "bonus_repair", text: "Ремонты" },
      { key: "bonus_receipt_1", text: "Чек от 5000 руб." },
      { key: "bonus_receipt_2", text: "Чек от 9000 руб." },
      { key: "bonus_proceeds", text: "Выручка за смену от 100 000 руб." },
      { key: "bonus_accessories", text: "Продажа кабеля или адаптера" },
      { key: "bonus_comprehensive_sales", text: "План комплексных продаж" },
    ],

    rules: {
      required: (v) => !!v || Number.parseInt(v) === 0 || "Обязательное поле",
      positiveNumber: (v) =>
        !v ||
        (window.isFinite(v) &&
          Number.parseInt(v) >= 0 &&
          Number.parseInt(v) === Number(v)) ||
        "Введите целое положительное число",
    },
    data: {},
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(v) {
      if (!v) return;
      this.data = {};
      this.textFields.forEach(
        (el) =>
          (this.data[el.key] =
            this.item && this.item.employee_data
              ? this.item.employee_data[el.key]
              : 0)
      );
    },
  },
  methods: {
    save() {
      tables
        .updateEmployeeData({
          ...this.request,
          employee: this.item.id,
          ...this.data,
        })
        .then(() => {
          this.$store.dispatch("alertSuccess", "Бонусы обновлены");
          this.dialog = false;
          this.$emit("save");
        })
        .catch((error) => this.$store.dispatch("alertError", error));
    },
  },
};
</script>
