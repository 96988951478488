<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="40%"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        :loading="loading"
        v-on="on"
      >
        Сотрудники
      </v-btn>
    </template>
    <v-card tile>
      <v-card-title
        class="headline"
      >
        Сотрудники <v-spacer />
        <v-switch
          v-model="isShowAll"
          label="Показать всех"
          class="mr-5"
        />
        <EmployeeAddDialog />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="getEmployeeList(isShowAll)"
          :sort-by="['is_active', 'first_name']"
          :sort-desc="false"
          dense
          disable-pagination
          disable-sort
          hide-default-footer
          item-key="id"
        >
          <template #[`item.is_active`]="{ item }">
            <v-simple-checkbox
              v-model="item.is_active"
              :ripple="false"
              @input="update(item)"
            />
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red"
          text
          @click="dialog = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import clients from "@/api/clients";
import EmployeeAddDialog from "@/components/tables/EmployeeAddDialog";

export default {
  name: "EmployeeListDialog",
  components: {
    EmployeeAddDialog,
  },
  props: {
    loading: Boolean,
  },
  data: () => ({
    dialog: false,
    headers: [
      { text: "Имя", value: "first_name" },
      { text: "Активен", value: "is_active" },
    ],

    isShowAll: false,
  }),
  computed: {
    ...mapGetters("clients", ["getEmployeeList"]),
  },
  methods: {
    ...mapActions("clients", ["updateEmployeeList"]),
    update({ id, is_active, first_name }) {
      clients
        .updateEmployee({ id, is_active })
        .then(() => {
          this.$store.dispatch(
            "alertSuccess",
            `Сотрудник ${first_name} успешно обновлён`
          ),
            this.updateEmployeeList();
        })
        .catch((error) => this.$store.dispatch("alertError", error));
    },
  },
};
</script>
