import api from '@/api';

export default {
  getCounterData(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .get('/tables/counter_month/', { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getEmployeeData(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .get('/tables/employee_month/', { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getTableData(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .get('/tables/table/', { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  updateCounterData(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/tables/counter_month/${payload.id}/`, payload,  { params: { counter: payload.counter, date: payload.date } })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  updateEmployeeShift(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .post('/tables/shift/', payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  updateEmployeeData(payload = {}) {
    return new Promise((resolve, reject) => {
      api
        .post('/tables/employee_month/', payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
