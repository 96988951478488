<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="20%"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        Добавить
      </v-btn>
    </template>
    <v-card tile>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="save"
      >
        <v-card-title class="headline">
          Добавить сотрудника
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="first_name"
            :rules="[rules.required, uniqueName]"
            label="Имя"
            required
          />
          <v-checkbox
            v-model="is_active"
            label="Активен"
          />
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
            color="success"
            type="submit"
            :disabled="!valid"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import clients from "@/api/clients";

export default {
  name: "EmployeeAddDialog",
  data: () => ({
    dialog: false,
    valid: false,

    first_name: "",
    is_active: true,

    rules: {
      required: (v) => !!v || "Обязательное поле",
    },
  }),
  computed: {
    ...mapGetters("clients", ["getEmployeeList", "getUserCompany"]),
  },
  methods: {
    ...mapActions("clients", ["updateEmployeeList"]),
    save() {
      clients
        .createEmployee({
          first_name: this.first_name,
          is_active: this.is_active,
          company: this.getUserCompany,
        })
        .then(() => {
          this.$store.dispatch(
            "alertSuccess",
            `Сотрудник ${this.first_name} успешно добавлен`
          ),
            this.updateEmployeeList();
          this.dialog = false;
        })
        .catch((error) => this.$store.dispatch("alertError", error));
    },
    uniqueName(v) {
      return (
        !this.getEmployeeList().find((el) => el.first_name === v) ||
        "Сотрудник с таким именем уже существует"
      );
    },
  },
};
</script>
