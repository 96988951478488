<template>
  <v-edit-dialog
    :return-value.sync="prepaid"
    cancel-text="Закрыть"
    large
    save-text="Сохранить"
    @open="open"
    @save="save"
  >
    {{ value | currency }}
    <v-icon
      class="ml-2"
      small
    >
      mdi-pencil
    </v-icon>
    <template #input>
      <v-text-field
        v-model="prepaid"
        :rules="[rules.required, rules.positiveNumber]"
        label="Аванс"
        single-line
      />
    </template>
  </v-edit-dialog>
</template>

<script>
import tables from "@/api/tables";

export default {
  name: "PrepaidEditDialog",
  props: {
    value: String,
    item: Object,
    request: Object,
  },
  data: () => ({
    rules: {
      required: (v) => !!v || Number.parseInt(v) === 0 || "Обязательное поле",
      positiveNumber: (v) =>
        !v ||
        (window.isFinite(v) &&
          Number.parseInt(v) >= 0 &&
          Number.parseInt(v) === Number(v)) ||
        "Введите целое положительное число",
    },
    prepaid: 0,
  }),
  methods: {
    open() {
      this.prepaid = this.value ? this.value : "0";
    },
    save() {
      tables
        .updateEmployeeData({
          ...this.request,
          employee: this.item.id,
          prepaid: this.prepaid,
        })
        .then(() => {
          this.$store.dispatch("alertSuccess", "Аванс обновлён"),
            this.$emit("input", this.prepaid);
        })
        .catch((error) => this.$store.dispatch("alertError", error));
    },
  },
};
</script>
